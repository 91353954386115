import React from "react"
import Layout from "../layouts/es"
import { ArrowRight, Check, XOctagon } from "react-feather"
import SEO from "../components/seo"

const PackagesPage = (props) => {
    return <Layout location={props.location}>
        <div>
            <SEO title="Precios y planes para firma de documentos"
                 description="Paquetes de firma electrónica avanzada. Paquetes por usuarios e integración API de firma electrónica. Integración de firma electrónica con software empresarial." />

            <section className="py-5 header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="position-absolute top-right text-primary-dark mt-n12 decor2">
                                <img src={"/img/ic_packages_1.svg"} alt="" />
                            </div>
                            <h3 align="center" className="spacing">Paquetes Tecalis Signature</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing">
                <div className="container">

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="mobile" id="table--desktop">
                                <div className="card card--price">
                                    <div className="card-body card--price--body">
                                        <div className="resumen d-flex justify-content-between align-items-center">
                                            <h4 className="font-weight-bold spacing pt-2 pl-2"> Planes y precios</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="custom-table mt-5 mb-5">
                                    <hr className="new4" style={{ position: "absolute", zIndex: 9999 }} />

                                    <table>
                                        <thead>
                                        <tr className="spacing">
                                            <th rowSpan="2" className="bg-white">
                                                Funcionalidades
                                            </th>
                                            <th>Estándar</th>
                                            <th>Premium</th>
                                            <th>Enterprise</th>
                                        </tr>
                                        <tr className="spacing">
                                            <td>25€
                                                <div align="center" className="font-sm-1">por usuario/mes</div>
                                                <div align="center">
                                                    <a href="" data-toggle="modal" data-target="#contacto" className="btn btn-tecalis-sm-mobile-white spacing mt-2 mb-3">
                                                        Contactar <span className="mobile">con ventas</span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                45€
                                                <div align="center" className="font-sm-1">por usuario/mes</div>
                                                <div align="center">
                                                    <a href="" data-toggle="modal" data-target="#contacto" className="btn btn-tecalis-sm-mobile-white spacing mt-2 mb-3">
                                                        Contactar <span className="mobile">con ventas</span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="font-price">¿No es suﬁciente?</span>
                                                <div align="center" className="font-sm-1">Consultanos sin compromiso</div>
                                                <div align="center">
                                                    <a href="" data-toggle="modal" data-target="#contacto" className="btn btn-tecalis-sm-mobile-white spacing mt-2 mb-3">
                                                        Contactar <span className="mobile">con ventas</span>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="spacing font-weight-bold">
                                            <td>Operaciones LTV</td>
                                            <td>
                                                <div align="center">75 operaciones</div>
                                            </td>
                                            <td>
                                                <div align="center">300 operaciones</div>
                                            </td>
                                            <td>
                                                <div align="center">Volumen personalizado</div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Notificaciones y recordatorios</td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Plantillas personalizadas</td>
                                            <td>
                                                <div align="center">Hasta 3 plantillas</div>
                                            </td>
                                            <td>
                                                <div align="center">Hasta 5 plantillas</div>
                                            </td>
                                            <td>
                                                <div align="center">Plantillas ilimitadas</div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Programación de envíos</td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Verificación mediante DNI</td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Envíos masivos</td>
                                            <td>
                                                <div align="center"><XOctagon data-feather="x-octagon" className="icon3 mt-0 text-tecalis" />
                                                </div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Estructuras organizativas y equipos</td>
                                            <td>
                                                <div align="center"><XOctagon className="icon3 mt-0 text-tecalis" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Personalización de marca</td>
                                            <td>
                                                <div align="center"><XOctagon className="icon3 mt-0 text-tecalis" />
                                                </div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        <tr className="spacing font-weight-bold">
                                            <td>Integración API</td>
                                            <td>
                                                <div align="center"><XOctagon className="icon3 mt-0 text-tecalis" />
                                                </div>
                                            </td>
                                            <td>
                                                <div align="center"><XOctagon className="icon3 mt-0 text-tecalis" />
                                                </div>
                                            </td>
                                            <td>
                                                <div align="center"><Check className="icon3 mt-0 text-green" /></div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br /><br />
                            </div>
                        </div>
                    </div>

                    <div className="desktop mt-5 mb-5" id="table--mobile">
                        <div className="pricing-table-wrapper">
                            <ul className="pricing-table">
                                <li className="pricing-table__item">
                                    <h3 className="pricing-table__title spacing">Estándar</h3>
                                    <p className="pricing-table__description">
                                        <span className="pricing-table__price spacing">25 €</span>
                                        <span className="pricing-table__label spacing"><b>por usuario/mes</b></span>
                                    </p>
                                    <ul className="pricing-table__products">
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />75 operaciones LTV</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Notificaciones y recordatorios</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Hasta 3 plantillas personalizadas</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Programación de envíos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Verificación mediante DNI</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><XOctagon className="icon mr-2 text-tecalis" />Envíos masivos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><XOctagon className="icon mr-2 text-tecalis" />Estructuras organizativas y equipos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><XOctagon className="icon mr-2 text-tecalis" />Personalización de marca</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><XOctagon className="icon mr-2 text-tecalis" />Integración API</a>
                                        </li>
                                    </ul>

                                    <a href="" className="btn btn-tecalis-block  spacing img-fluid">Comenzar prueba</a>
                                </li>

                                <li className="pricing-table__item pricing-table__item--popular" data-popular="Popular">
                                    <h3 className="pricing-table__title">Premium</h3>
                                    <p className="pricing-table__description">
                                        <span className="pricing-table__price">45 €</span>
                                        <span className="pricing-table__label"><b>por usuario/mes</b></span>
                                    </p>
                                    <ul className="pricing-table__products">
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />300 operaciones LTV</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Notificaciones y recordatorios</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check data-feather="check" className="icon mr-2 text-green" />Hasta 5 plantillas personalizadas</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Programación de envíos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Verificación mediante DNI</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Envíos masivos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Estructuras organizativas y equipos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Personalización de marca</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><XOctagon className="icon mr-2 text-tecalis" />Integración API</a>
                                        </li>
                                    </ul>
                                    <a href="" className="btn btn-tecalis-block  spacing img-fluid">Comenzar prueba</a>
                                </li>

                                <li className="pricing-table__item">
                                    <h3 className="pricing-table__title">Enterprise</h3>
                                    <p className="pricing-table__description">
                                        <span className="pricing-table__price2"><b>¿No es suficiente?</b></span>
                                        <span className="pricing-table__label spacing"><b>Consultanos sin compromiso</b></span>
                                    </p>
                                    <ul className="pricing-table__products">
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Volumen LTV personalizado</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Notificaciones y recordatorios</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Plantillas personalizadas ilimitadas</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Programación de envíos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Verificación mediante DNI</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check data-feather="check" className="icon mr-2 text-green" />Envíos masivos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check data-feather="check" className="icon mr-2 text-green" />Estructuras organizativas y equipos</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Personalización de marca</a>
                                        </li>
                                        <li className="pricing-table__product spacing">
                                            <a href=""><Check className="icon mr-2 text-green" />Integración API</a>
                                        </li>
                                    </ul>

                                    <a href="" data-toggle="modal" data-target="#contacto" className="btn btn-tecalis-block  spacing img-fluid">Contactar con ventas</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 links">
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                    <img src={"/img/home_signature_3.svg"} alt="" />
                </div>

                <div className="container">
                    <div className="links-wrapper">
                        <div className="row links-card">
                            <div className="col-xl-6">
                                <div className="card hvr-float mb-4 price--card">
                                    <div className="card-body">
                                        <div align="center">
                                            <h5><span className="badge rounded-pill bg-info mt-3 py-2 pr-3 pl-3">Pack Enterprise</span></h5>
                                            <h3>¿No es suficiente? Descubre nuestros paquetes personalizados</h3>
                                            <h5 align="left">👉 Planes ajustados a las necesidades específicas de tu empresa</h5>
                                        </div>
                                        <p className="text-black-80 spacing mt-2 mb-4 pl-5 pr-5">
                                            Tecalis Signature dispone de planes personalizados que se ajustan a las necesidades concretas de las grandes compañías. Además, nuestra
                                            solución de firma electrónica puede integrarse con cualquier software (API), para que cualquier empresa tenga acceso a nuestra tecnología.
                                            sin tener que cambiar sus sistemas y procesos.
                                        </p>
                                        <p>
                                            <a href="" data-toggle="modal" data-target="#contacto" className="link-tecalis pl-5 pr-5 spacing font-weight-bold">
                                                Contactar con ventas <ArrowRight className="icon" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="card hvr-float mb-4 price--card">
                                    <div className="card-body">
                                        <div align="center">
                                            <h5><span className="badge rounded-pill bg-info mt-3 py-2 pr-3 pl-3">Integración API</span></h5>
                                            <h3>Integra la firma digital con cualquier software, CRM o ERP</h3>
                                            <h5 align="left">👉 Infinitas posibilidades para tus sistemas de negocio</h5>
                                        </div>

                                        <p className="text-black-80 spacing mt-2 mb-4 pl-5 pr-5">
                                            Nuestra solución de firma electrónica puede integrarse con cualquier software empresarial gracias a nuestra API, para que cualquier empresa
                                            tenga acceso a nuestra tecnología sin tener que sustituir sus sistemas empresariales. Gracias a nuestra integración, te ofrecemos infinitas
                                            posibilidades para tu negocio.
                                        </p>

                                        <p>
                                            <a href="" data-toggle="modal" data-target="#contacto" className="link-tecalis pl-5 pr-5 spacing font-weight-bold">
                                                Contactar con ventas <ArrowRight className="icon"/>
                                            </a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
}

export default PackagesPage
